<template>
  <div class="p-l-md p-r-md">
    <ListFrame
      :immediateInit="false"
      :viewConf="{showReorder:false,showPager:{show:false,pageSize:{initial:5000}}}"
      :customedParams="{contentType,teachingId}"
      :getPageFn="getPage"
    >
      <template v-slot:list="{list}">
        <transition-group name="sort" tag="div">
          <div v-for="(item,i) in list" :key="item.id" class="flex m-b-md myitem">
            <div class="sortbuttons">
              <button v-if="i>0" class="txt-btn text-primary" @click="move(list,i-1,i)">
                <Icon type="md-arrow-round-up" />
              </button>
              <button v-if="i<list.length-1" class="txt-btn text-primary" @click="move(list,i,i+1)">
                <Icon type="md-arrow-round-down" />
              </button>
            </div>
            <div>
              <TeachingItem :info="item" :hiddeStep="true" />
              <Button type="warning" @click="preview(item)" style="margin-right:1.5em;">查看</Button>
              <span class="status-span active" v-if="item.status==3">已上架</span>
              <span class="status-span" v-else>
                已下架
                <span style="padding-left:1em">下架原因：{{item.remark}}</span>
              </span>
            </div>
          </div>
        </transition-group>
      </template>

      <template v-slot:conditions="{requestFn}">
        <div style="order:10; margin-top:1em;width:100%" class="flex">
          <TeachingMaterial5lvlSelect @select="teachingId=$event" class="m-b-xs" />
          <Button type="primary" v-if="teachingId" @click="requestFn">排序</Button>
        </div>
      </template>
    </ListFrame>
  </div>
</template>

<script>
import { getList, modifySort } from "./services/teachingService";
import { getSubmitedInfo, offShelf, onShelf } from "./services/api";

export default {
  data() {
    return {
      teachingId: "",
      timmer: 0
    };
  },
  computed: {
    is6Verify() {
      return this.$store.getters.getRoleCode === 6;
    },
    contentType() {
      return this.$store.getters.getLaun + "Teaching";
    }
  },
  methods: {
    getPage(params) {
      return getList("published", params).then(res => {
        res.rows.sort((a, b) => a.sort - b.sort);
        return res;
      });
    },
    preview(item) {
      getSubmitedInfo({
        bizId: item.id,
        taskName: this.$store.getters.getRoleCode,
        contentType: item.contentType
      }).then(res => {
        this.$mobilePreview(res.content);
      });
    },
    move(list, ind1, ind2) {
      let temp = list[ind1];
      list[ind1] = list[ind2];
      list[ind2] = temp;
      let data = list.map((ele, ind) => {
        ele.sort = ind;
        return { id: ele.id, sort: ind };
      });
      this.$forceUpdate();

      clearTimeout(this.timmer);
      this.timmer = setTimeout(() => {
        modifySort(this.contentType, data).then(() => {
          this.$Message.success("排序成功");
        });
      }, 1500);
    }
  }
};
</script>

<style scoped>
.myitem{
  background: white;
}
.sort-move {
  transition: transform 0.5s;
}
.sortbuttons {
  width: 50px;
  font-size: 22px;
  margin-right: 16px;
  align-self: center;
}
</style>